import roles from "./roles";

let exports = {};

exports.isWorker = (user) => {
    return user.roles.filter(role =>
        (
            role.id === roles.EMPLOYEE ||
            role.id === roles.WORKER
        )
    ).length > 0;
}

exports.isAdmin = (user) => {
    return user.roles.filter(role =>
        (
            role.id === roles.SUPERADMIN ||
            role.id === roles.ADMIN ||
            role.id === roles.DATA_ENTRY_OPERATOR ||
            role.id === roles.RECRUITER
        )
    ).length > 0;
}

export default exports;