import Vue from 'vue';
import axios from 'axios';
import router from '@/router/index.js';

// Authorization
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token)
            config.headers['Authorization'] = 'Bearer ' + token;
            
        return config;
    },
    err => {
        return Promise.reject(err);
});

//Errors
axios.interceptors.response.use(
    response => response,
    async err => {
        const vm = new Vue()
        const refreshToken = localStorage.getItem('refreshToken');
        if (err.response && err.response.status === 401 && refreshToken) {
            return store.dispatch('auth/refreshToken', err);
        } else {
            if(err.response.data.error != undefined){
                vm.$toasted.error(err.response.data.error);
                if(err.response.data.error == "User does not have the right permissions")
                    router.push({ path: '/' }); 
            }
            else if(err.response.data.message != undefined){
                let errors = err.response.data.message;
                if(typeof errors === 'string' || errors instanceof String)
                    vm.$toasted.error(errors);
                else
                    vm.$toasted.error(err.response.data[Object.keys(err.response.data)[i]][j]);
            }else{
                for(let i = 0; i < Object.keys(err.response.data).length; i++){
                    for(let j = 0; j < err.response.data[Object.keys(err.response.data)[i]].length; j++)
                        vm.$toasted.error(err.response.data[Object.keys(err.response.data)[i]][j]);
                }
            }
            return Promise.reject(err.response);
        }
});
