import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';

export const initialState = {
    loading: false,
    headers: [
        { key: 'actions', label: '', stickyColumn: true },
        { key: 'name', label: 'Name', sortable: true, stickyColumn: true },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'speciality', label: 'Speciality', sortable: true },
        { key: 'contacts', label: 'Contacts', sortable: true },
        { key: 'ssn', label: 'SSN', sortable: true },
        { key: 'all_stats', label: 'All stats', sortable: false },
        { key: 'status', label: 'Status', sortable: false }
    ],
    items:[],
    skills:[],
    meta:{
        current_page:1,
        last_page:1,
        total:0
    },
    //details
    loadingDetails:false,
    details: {},
    loadingBasicInformation:true,
    basicInformation: {},
    loadingActivity: false,
    activity:{},
    loadingReviews: true,
    reviews:{},
    loadingWorkHistory: true,
    workHistory:{},
    metaWorkHistory:{
        current_page:1,
        last_page:1,
        total:0
    },
    loadingEmploymentHistory: true,
    employmentHistory:{},
};

const workers = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    mutations,
    actions
};

export default workers;