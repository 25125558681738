import Vue from 'vue';

export default {
    INIT_HEADERS(state, date) {
        state.headers = date;
    },
    ADD_HEADER(state, data) {
        if (state.headers.find(e => e.key == data.key) == undefined) {
            state.headers.push(data);
            Vue.set(state.headers, data, state.headers.length - 1);
            localStorage.setItem("work-orders-headers", JSON.stringify(state.headers))
        }
    },
    REMOVE_HEADER(state, value) {
        let index = state.headers.indexOf(state.headers.find(e => e.key == value));
        if (index != -1) {
            state.headers.splice(index, 1);
            localStorage.setItem("work-orders-headers", JSON.stringify(state.headers))
        }
    },
    SET_LOADING_ITEMS(state, value){
        state.loading = value;
    },
    RESET_LIST(state){
        state.items = [];
    },
    SET_ITEMS(state,data){
        state.items.push(...data.workOrders);
        state.companies = data.companies;
        state.statuses = data.statuses;
    },
    SET_ITEMS_ASSIGN(state,data){
        state.itemsAssign.push(...data.workOrders);
        state.companies = data.companies;
        state.statuses = data.statuses;
    },
    SET_META(state,data){
        state.meta.current_page = data.current_page;
        state.meta.last_page = data.last_page;
        state.meta.total = data.total;
    },
    SET_LOADING_PARAMS(state, value){
        state.loadingParams = value;
    },
    SET_PARAMS(state,data){
        state.params = data;
    },
    SET_BRANCHES(state,data){
        state.branches = data;
    },
    SET_LOADING_NEW_WORK_ORDER(state, value){
        state.loadingNewWorkOrder = value;
    },
    SET_NEW_WORK_ORDER(state,data){
        state.workOrderId = data.id;
    },
    SET_LOADING_CREATE_POSITION(state, value){
        state.loadingCreatePosition = value;
    },
    SET_LOADING_CONTACT(state, value){
        state.loadingContact = value;
    },
    SET_LOADING_BILLING(state, value){
        state.loadingBilling = value;
    },
    SET_LOADING_FILES(state, value){
        state.loadingFiles = value;
    },
    SET_LOADING_WORK_ORDER(state, value){
        state.loadingWorkOrder = value;
    },
    SET_WORK_ORDER(state,data){
        state.workOrder = data.workorder;
        state.params = {
            companies: data.companies,
            job_statuses: data.job_statuses,
            job_categories: data.job_category,
            states: data.states,
            skills: data.skills,
            calculators: data.calculators,
        }
    },
    SET_POSITIONS(state,data){
        state.positions = data.positions;
    },
    //details
    SET_LOADING_ITEM_DETAILS(state, value){
        state.loadingDetails = value;
    },
    SET_ITEM_DETAILS(state,data){
        state.details = data;
    },
    SET_LOADING_GENERAL_INFO(state, value){
        state.loadingGeneralInfo = value;
    },
    SET_GENERAL_INFO(state,data){
        state.generalInfo = data;
    },
    SET_LOADING_HISTORY(state, value){
        state.loadingHistory = value;
    },
    SET_HISTORY(state,data){
        state.history = data;
    },
    SET_LOADING_CONTACTS(state, value){
        state.loadingContacts = value;
    },
    SET_CONTACTS(state,data){
        state.contacts = data;
    },
    SET_LOADING_BILLING_DETAILS(state, value){
        state.loadingBillingDetails = value;
    },
    SET_BILLING_DETAILS(state,data){
        state.billingDetails = data;
    },

}