import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';

export const initialState = {
    unlogged: {
        activate: false,
        reset: false,
        status: false,
        message: ''
    },
    loadingCurrentUser:false,
    currentUser:{}
};

const auth = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    mutations,
    actions
};

export default auth;