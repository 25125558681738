import axios from 'axios';

export default {
    async getCitiesByIdState(context, id){
        context.commit('SET_LOADING_CITIES', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/address/state/${id}/cities`)
        .then( result => {
            context.commit('SET_CITIES', result.data.data);
            context.commit('SET_LOADING_CITIES', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_CITIES', false );
        });
    },
    async getZipCodesByIdCity(context, id){
        context.commit('SET_LOADING_ZIP_CODES', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/address/city/${id}/zipcodes`)
        .then(result => {
            context.commit('SET_ZIP_CODES', result.data.data);
            context.commit('SET_LOADING_ZIP_CODES', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_ZIP_CODES', false );
        });
    },
    async getCompanyList(context){
        context.commit('SET_LOADING_COMPANY_LIST', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/company/list`)
        .then(result => {
            context.commit('SET_COMPANY_LIST', result.data.data);
            context.commit('SET_LOADING_COMPANY_LIST', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_COMPANY_LIST', false );
        });
    },
    async getWorkOrdersList(context){
        context.commit('SET_LOADING_WORK_ORDERS_LIST', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/company/workorder-list`)
        .then(result => {
            context.commit('SET_WORK_ORDERS_LIST', result.data.data);
            context.commit('SET_LOADING_WORK_ORDERS_LIST', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORK_ORDERS_LIST', false );
        });
    },
    async getWorkersListByWorkOrder(context,id){
        context.commit('SET_LOADING_WORKERS_LIST', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/company/workorder/${id}/worker-list`)
        .then(result => {
            context.commit('SET_WORKERS_LIST', result.data.data);
            context.commit('SET_LOADING_WORKERS_LIST', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORKERS_LIST', false );
        });
    },
}
