import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import DefaultLayout from "../layouts/Default";
import EmptyLayout from "../layouts/Empty";
import checkRole from "../assets/const/checkRole";

Vue.use(VueRouter);
Vue.component("default-layout", DefaultLayout);
Vue.component("empty-layout", EmptyLayout);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('accessToken');
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  let isAdmin = currentUser != null ? checkRole.isAdmin(currentUser) : false;

  if (to.matched.some(record => record.meta.private)) {
    if(currentUser != null && to.meta.workerHasAccess !== true && !isAdmin)
      next({name: 'DataEntry'});
    else if (token || to.name === 'DataEntryCreate') {
      next();
    }
    else {
      next({name: 'Login'});
    }
  } else if (to.name === 'Login' && token) {
    next({path: '/'});
  } else if (to.meta.unlogged === true) {
    next();
  } else {
    if(currentUser != null && to.name != 'DataEntryView' && to.name != 'DataEntryCreate' && to.name != 'DataEntryUpdate' && to.name != 'DataEntry' && !isAdmin)
      next({name: 'DataEntry'});
    next();
  }
});

export default router;