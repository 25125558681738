<template>
  <div>
    <div v-if="currentUser == null || loading" class="loading">
      <div class="text-center text-secondary my-2 mt-5 pt-5">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </div>
    <b-row v-else class="justify-content default-layout">
      <b-col v-if="showMobileMenu || width > 593" class="col-auto menu ">
        <b-row  v-if="showAllMenu || showMobileMenu" class="title">
          <b-col class="text-center">TimeSheet</b-col>
          <b-col v-if="width <= 593" class="col-auto"><b-icon  icon="x" class="icon-menu"  @click="showMenu()"/></b-col>
        </b-row>     
        
        <div v-if="isWorkersOrEmployees" class="line">
          <b-row @click="redirect('/applicant-details')">
            <b-col class="col-auto pr-0"><img src="@/assets/icons/users_list.svg"/></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'ApplicantDetails'? 'selected-item-menu':'' ">Applicant details</span>
              </b-col>
          </b-row>
        </div>
        <div v-if="isAdmin" class="line">
          <b-row @click="redirect('/workorders/create')">
            <b-col class="col-auto pr-0"><img src="@/assets/icons/work.svg"/></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'CreateWorkOrders'? 'selected-item-menu':'' ">Create Work Order</span>
              </b-col>
          </b-row>
        </div>
        <div class="line">
          <b-row @click="redirect('/workorders')">
            <b-col class="col-auto pr-0"><img src="@/assets/icons/work.svg"/></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'WorkOrders'? 'selected-item-menu':'' ">Work Orders List</span>
            </b-col>
          </b-row>
        </div>
        <div class="line">
          <b-row @click="redirect('/worker/data-entry')">
            <b-col class="col-auto pr-0"><b-icon icon="calendar2-minus" class="icon"></b-icon></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'DataEntry'? 'selected-item-menu':'' ">List time sheet</span>
            </b-col>
          </b-row>
        </div>
        <div class="line">
          <b-row @click="redirect('/worker/data-entry/create')">
            <b-col class="col-auto pr-0"><b-icon icon="clock" class="icon"></b-icon></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.path == '/worker/data-entry/create'? 'selected-item-menu':'' ">Create time sheet</span>
            </b-col>
          </b-row>
        </div>
        <div v-if="isAdmin" class="line">
          <b-row @click="redirect('/workers')">
            <b-col class="col-auto pr-0"><img src="@/assets/icons/users_list.svg"/></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'Workers'? 'selected-item-menu':'' ">Workers list</span>
            </b-col>
          </b-row>
        </div>
        <div v-if="isAdmin" class="line">       
          <b-row @click="redirect('/applicants')">
            <b-col class="col-auto pr-0"><img src="@/assets/icons/users_list.svg"/></b-col>
            <b-col v-if="showAllMenu || showMobileMenu">
              <span :class="$route.name == 'Applicants'? 'selected-item-menu':'' ">Applicants List</span>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col v-if="!showMobileMenu" class="content-slot">
        <b-row class="justify-content header">
          <b-col class="col-auto pr-0" @click="showMenu()">
            <b-icon  :icon="width > 593 && showAllMenu? 'x':'list'" class="icon-menu" /> 
          </b-col>
          <b-col class="pl-3">
            <span class="title">{{ $route.meta.title }}</span>
          </b-col>
          <b-col v-if="false" class="col-auto">
            <img src="@/assets/icons/help.svg" class="mr-2"/>
          </b-col>
          <b-col v-if="false" class="col-auto">
            <img src="@/assets/icons/settings.svg" class="mr-2"/>
          </b-col>
          <b-col class="col-auto">
            <div v-on:click="isActiveLogout = !isActiveLogout" class="user"><img src="@/assets/icons/user.png" class="icon"/><span>{{currentUser.name}}</span></div>
            <div v-if="isActiveLogout" class="logout_menu" v-on:click="logout()" v-click-outside="outsideLogout">
              <div class="logout"><img src="@/assets/icons/logout.svg" class="icon"/>Logout</div>
            </div>
          </b-col>
        </b-row>
        <slot/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import checkRole from "../assets/const/checkRole";

export default {
  name: "Dafault",
  data() {
    return {
      loading: true,
      isActiveLogout: false,
      showAllMenu: true,
      showMobileMenu: false,
      width: window.innerWidth,
    };
  },
  created(){
    this.loading = false;
    window.addEventListener('resize', this.updateWidth);
    if(this.width <=593){
      this.showAllMenu = true;
      
    }
  },
  computed:{
    currentUser(){
      return JSON.parse(localStorage.getItem('currentUser'));
    },
    isAdmin(){
      return checkRole.isAdmin(this.currentUser);
    },
    isWorkersOrEmployees(){
      return checkRole.isWorker(this.currentUser);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout',  this.$router);
    },
    outsideLogout(){
      this.isActiveLogout = false;
    },
    updateWidth() {
      this.width = window.innerWidth;

    },
    showMenu(){
      if(this.width <=593)
        this.showMobileMenu = !this.showMobileMenu;
      else 
        this.showAllMenu = !this.showAllMenu;
    },
    redirect(value){
      this.$router.push({ path: value });
      if(this.width <=593)
        this.showMobileMenu = false;

    }
  },
};
</script>
<style scoped>
.icon{
  color: white;
  font-size: 18px;
}
</style>