import axios from 'axios';
import router from '@/router';
import checkRole from "../../assets/const/checkRole";

export default {
    async login(context, data) {
        context.commit('SET_LOADING_CURRENT_USER', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/auth/login`, data)
        .then(result => {
            localStorage.setItem('accessToken', result.data.access_token);
            localStorage.setItem('currentUser', JSON.stringify(result.data.user));
            context.commit('SET_CURRENT_USER', result.data.user);
            context.commit('SET_LOADING_CURRENT_USER', false);
            let isWorkersOrEmployees = checkRole.isWorker(result.data.user);
            if(isWorkersOrEmployees)
                router.push({ path: '/worker/data-entry' });
            else
                router.push({ path: '/' });
        })
        .catch(() => {
            context.commit('SET_LOADING_CURRENT_USER', false);
        });
    },
    async logout({ dispatch }, router) {
        await axios
            .get(`${process.env.VUE_APP_API_URL}/api/v1/auth/logout`)
            .catch(() => {});
            dispatch('clearCurrentUser');
            localStorage.clear();
            if (router) router.push({ path: '/login' });
    },
    clearCurrentUser(context) {
        context.commit('SET_CURRENT_USER');
    },
    clearUnloggedData(context) {
        context.commit('SET_UNLOGGED_INIT_DATA');
    },
    async activate(context, data) {
        context.commit('SET_LOADING_CURRENT_USER', true);
        await axios
            .get(`${process.env.VUE_APP_API_URL}/api/v1/auth/activation/${data.token}?email=${data.email}`)
            .then(result => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', result.data);
            })
            .catch(() => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', {activated: false, message: 'Something is wrong!'});
            });
    },
    async forgotPass(context, data) {
        context.commit('SET_LOADING_CURRENT_USER', true);
        await axios
            .post(`${process.env.VUE_APP_API_URL}/api/v1/auth/forgot-pass`, data)
            .then(result => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', result.data);
            })
            .catch(() => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', {status: false, message: 'Something is wrong!'});
            });
    },
    async resetPass(context, data) {
        context.commit('SET_LOADING_CURRENT_USER', true);
        await axios
            .post(`${process.env.VUE_APP_API_URL}/api/v1/auth/reset-pass/${data.token}?email=${data.email}`,
                data.user)
            .then(result => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', result.data);
            })
            .catch(() => {
                context.commit('SET_LOADING_CURRENT_USER', false);
                context.commit('SET_UNLOGGED_DATA', {status: false, message: 'Something is wrong!'});
            });
    }
}
