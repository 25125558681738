import moment from 'moment';
import helpers from '../../assets/const/helpersTimeSheet';

export default {
    totalHours(state) {
        let total = '0:00';
            for(let i = 0; i < state.days.length; i++){
                if(state.days[i].dateStart != null && state.days[i].dateEnd != null && state.days[i].timeStart != null && state.days[i].timeEnd != null){

                    let diff = helpers.getDiffMinutes(state.days[i].dateStart+'T'+state.days[i].timeStart, state.days[i].dateEnd+'T'+state.days[i].timeEnd);
                     
                    let oldTotal = total.split(':');
                    diff += oldTotal[0] * 60 + parseInt(oldTotal[1]);
                    total = helpers.getTimeString(diff); 
                }
            }
        
        return total;
    },
    paidUnpaidHours(state, getters){
        let res = [getters.totalHours,'0:00'];
            for(let i = 0; i < state.days.length; i++){
                if(state.days[i].dateStart != null && state.days[i].dateEnd != null && state.days[i].timeStart != null && state.days[i].timeEnd != null){
                    for(let j = 0; j < state.days[i].breaks.length; j++){
                        if(state.days[i].breaks[j].type != null && state.days[i].breaks[j].timeStart !=null && state.days[i].breaks[j].timeEnd){
                            let diff = helpers.getDiffMinutes(state.days[i].dateStart+'T'+state.days[i].breaks[j].timeStart, state.days[i].dateEnd+'T'+state.days[i].breaks[j].timeEnd); 

                            
                            if(state.days[i].dateStart != state.days[i].dateEnd 
                                && helpers.changeColonToDot(state.days[i].breaks[j].timeStart) < helpers.changeColonToDot(state.days[i].breaks[j].timeEnd)){

                                diff = helpers.getDiffMinutes(state.days[i].dateStart+'T'+state.days[i].breaks[j].timeStart, state.days[i].dateStart+'T'+state.days[i].breaks[j].timeEnd); 
                            }
                            let unpaidHours = res[1].split(':');
                            let unpaidHoursMin = parseInt(unpaidHours[0]) * 60 + parseInt(unpaidHours[1]);

                            let paidHours = res[0].split(':');
                            let paidHoursMin = parseInt(paidHours[0]) * 60 + parseInt(paidHours[1] == undefined ? 0 : paidHours[1]);
                            if(state.breaksTypes[state.days[i].breaks[j].type].paid){
                                if(state.breaksTypes[state.days[i].breaks[j].type].duration < diff)
                                {
                                    diff -= state.breaksTypes[state.days[i].breaks[j].type].duration;
                                    res[1] = helpers.getTimeString(unpaidHoursMin + diff);
                                    res[0] = helpers.getTimeString(paidHoursMin - diff); 
                                }
                            }
                            else{
                                res[1] = helpers.getTimeString(unpaidHoursMin + diff);
                                res[0] = helpers.getTimeString(paidHoursMin - diff);
                            }
                        }
                    }
                }

                if(res[0].length > 5 || res[1].length > 5 || parseFloat(res[0]) < 0 || parseFloat(res[1]) < 0 ) {
                    res[0] = res[1] = '0.00';
                    res.push('The time is incorrect, please check the entered time');
                }
            }
            return res;
    },
}