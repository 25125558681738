import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';

export const initialState = {
    loadingCities:false,
    loadingZipCodes:false,
    loadingCompanies:false,
    loadingWorkOrders:false,
    loadingWorkers:false,
    cities:[],
    zipCodes:[],
    companies: [],
    workOrders:[],
    workers:[]
};

const auth = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    mutations,
    actions
};

export default auth;