import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

import applicants from './applicants';
import auth from './auth';
import common from './common';
import workOrders from './workOrders';
import workers from './workers';
import timeSheet from './timeSheet';

Vue.use(Vuex);

export const storeData = {
    modules: {
        auth: _.cloneDeep(auth),
        applicants: _.cloneDeep(applicants),
        common: _.cloneDeep(common),
        workOrders: _.cloneDeep(workOrders),
        workers: _.cloneDeep(workers),
        timeSheet: _.cloneDeep(timeSheet),
    }
};

export default new Vuex.Store(storeData);