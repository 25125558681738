// const Dashboard = () => import(/* webpackChunkName: "pages" */ '@/views/Dashboard.vue');
//auth
const Login = () =>import(/* webpackChunkName: "pages" */ '@/views/pages/auth/Login');
const Activation = () => import(/* webpackChunkName: "pages" */ '@/views/pages/auth/Activation');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/pages/auth/ForgotPassword');
const ResetPassword = () => import(/* webpackChunkName: "pages" */ '@/views/pages/auth/ResetPassword');
//pages
const Applicants = () => import(/* webpackChunkName: "pages" */ '@/views/Applicants');
const ApplicantDetail = () => import(/* webpackChunkName: "pages" */ '@/views/pages/ApplicantDetail');
const WorkOrders = () => import(/* webpackChunkName: "pages" */ '@/views/WorkOrders');
const WorkOrdersDetail = () => import(/* webpackChunkName: "pages" */ '@/views/pages/WorkOrderDetail');
const CreateWorkOrders = () => import(/* webpackChunkName: "pages" */ '@/views/CreateWorkOrder');
const Workers = () => import(/* webpackChunkName: "pages" */ '@/views/Workers');
const WorkersDetail = () => import(/* webpackChunkName: "pages" */ '@/views/pages/WorkersDetail');
const CreateOrUpdateTimeSheet = () => import(/* webpackChunkName: "pages" */ '@/views/CreateOrUpdateTimeSheet');
const TimeSheet = () => import(/* webpackChunkName: "pages" */ '@/views/TimeSheet');
const ViewTimeSheet = () => import(/* webpackChunkName: "pages" */ '@/views/pages/ViewTimeSheet');

const routes = [
    {
        path:'/',
        name:"WorkOrders",
        component:WorkOrders,
        meta: {
            private:true,
            title: 'Work orders',
            icon: 'work'
        }
    },
    //WorkOrders
    {
        path:'/workorders',
        name:"WorkOrders",
        component:WorkOrders,
        meta: {
            private:true,
            title: 'Work orders',
            icon: 'work',
            workerHasAccess: true
        }
    },
    {
        path:'/workorders/create',
        name:"CreateWorkOrders",
        component:CreateWorkOrders,
        meta: {
            private:true,
            title: 'Work orders',
            icon: 'work',
        }
    },
    {
        path:'/workorders/:id',
        name:"WorkOrdersDetail",
        component:WorkOrdersDetail,
        meta: {
            private:true,
            title: 'Work orders detail',
            icon: 'work',
            workerHasAccess: true
        },
    },
    //Workers
    {
        path:'/workers',
        name:"Workers",
        component:Workers,
        meta: {
            private:true,
            title: 'Workers',
            icon: 'work',
        }
    },
    {
        path:'/workers/:id',
        name:"WorkersDetail",
        component:WorkersDetail,
        meta: {
            private:true,
            title: 'Workers detail',
            icon: 'users',
        },
    },
    {
        path:'/worker/data-entry',
        name:'DataEntry',
        component:TimeSheet,
        meta: {
            private: true,
            title: 'Time sheet',
            icon: 'calendar2-minus',
            workerHasAccess: true
        },
    },
    {
        path:'/worker/data-entry/create',
        name:'DataEntryCreate',
        component:CreateOrUpdateTimeSheet,
        meta: {
            private: true,
            title: 'New time sheet',
            icon: 'clock',
            workerHasAccess: true
        },
    },
    {
        path:'/worker/data-entry/:id/edit',
        name:'DataEntryUpdate',
        component:CreateOrUpdateTimeSheet,
        meta: {
            private: true,
            title: 'Update time sheet',
            icon: 'clock',
            workerHasAccess: true
        },
    },
    {
        path:'/worker/data-entry/:id',
        name:'DataEntryView',
        component:ViewTimeSheet,
        meta: {
            private: true,
            title: 'View time sheet',
            icon: 'clock',
            workerHasAccess: true
        },
    },
    //Applicants
    {
        path:'/applicant-details',
        name:"ApplicantDetails",
        component:ApplicantDetail,
        meta: {
            private:true,
            title: 'Applicant details',
            icon: 'users',
            workerHasAccess: true
        }
    },
    {
        path:'/applicants',
        name:"Applicants",
        component:Applicants,
        meta: {
            private:true,
            title: 'Applicant',
            icon: 'users',
        },
    },
    {
        path:'/applicants/:id',
        name:"ApplicantDetail",
        component:ApplicantDetail,
        meta: {
            private:true,
            title: 'Applicant Detail',
            icon: 'users',
        },
    },
    //Auth
    {
        path:'/user/activation/:token',
        name:'Activation',
        component:Activation,
        meta: {
            title: 'Activation',
            unlogged: true
        },
    },
    {
        path:'/user/forgot-password',
        name:'ForgotPassword',
        component:ForgotPassword,
        meta: {
            title: 'Forgot Password',
            unlogged: true
        },
    },
    {
        path:'/user/reset-password/:token',
        name:'ResetPassword',
        component:ResetPassword,
        meta: {
            title: 'Reset Password',
            unlogged: true
        },
    },
    //{ path: '*', component: NotFound }
    { path: '*', redirect: '/'},
    {
        path:'/login',
        name:'Login',
        component:Login,
        meta: {
            layout: "empty-layout",
        },
    },

  ];
  
  export default routes;