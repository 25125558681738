import Vue from 'vue';
import moment  from 'moment';

export default {
    INIT_HEADERS(state, date) {
        state.headers = date;
    },
    ADD_HEADER(state, data) {
        if (state.headers.find(e => e.key == data.key) == undefined) {
            state.headers.push(data);
            Vue.set(state.headers, data, state.headers.length - 1);
            localStorage.setItem("time-sheet-headers", JSON.stringify(state.headers))
        }
    },
    REMOVE_HEADER(state, value) {
        let index = state.headers.indexOf(state.headers.find(e => e.key == value));
        if (index != -1) {
            state.headers.splice(index, 1);
            localStorage.setItem("time-sheet-headers", JSON.stringify(state.headers))
        }
    },
    SET_LOADING_LIST(state, value){
        state.loading = value;
    },
    SET_LIST(state,data){
        state.items = data;
    },
    SET_META(state,data){
        state.meta.current_page = data.current_page;
        state.meta.last_page = data.last_page;
        state.meta.total = data.total;
    },
    SET_LOADING_WORK_ORDERS(state, value){
        state.loadingWorkOrders = value;
    },
    SET_WORK_ORDERS(state,data){
        state.workOrders = data;
    },
    SET_BREAKS_TYPES(state,data){
        state.breaksTypes = data;
        state.dataEntryUpdate= {
            company: null,
            user_name: '',
            user_email: '',
            notes: '',
            user_location:'',
            user_position:'',
            file:null,
        };
        state.days = [{
            errors: [],
            breaks:[],
            dateStart:null,
            dateEnd:null,
            timeStart: null,
            timeEnd:null
        }];
        state.dataToDelete = {
            days:[],
            breaks:[],
        };
        state.worker = null;
    },
    SET_CHECK_DATE(state,data){
        state.checkDate = data;
    },
    SET_DATA_ENTRY_UPDATE_LOADING(state, value){
        state.dataEntryUpdateLoading = value;
    },
    SET_DATA_ENTRY_UPDATE(state,data){
        state.days = [];
        for(let i = 0; i < data.dates.length; i++){
            let breaks = [];
            if(data.dates[i].breaks != undefined){
                for(let j = 0; j < data.dates[i].breaks.length; j++){
                    let type = state.breaksTypes.indexOf(state.breaksTypes.find(e=> e.id == data.dates[i].breaks[j].break_type));
                    breaks.push({
                        id: data.dates[i].breaks[j].id, 
                        timeStart: data.dates[i].breaks[j].start, 
                        timeEnd: data.dates[i].breaks[j].end,
                        type: type == -1 ? null : type 
                    });
                }
            }
            
            
            let allTime = (data.dates[i].total_hours + data.dates[i].break_paid).toString().split('.');
            let time = allTime.length == 2 ? (parseInt(allTime[0]) * 60) + (parseInt(allTime[1].substr(0, 2))/100*60) : parseInt(allTime[0]) * 60 ;
            let dateEnd = moment(data.dates[i].date+'T'+data.dates[i].time.start).add(time,'minutes').format("YYYY-MM-DD");

            state.days.push({
                errors: [],
                timesheet_id: data.dates[i].timesheet_id,
                breaks: breaks,
                dateStart:data.dates[i].date,
                dateEnd: dateEnd,
                timeStart: data.dates[i].time.start,
                timeEnd: data.dates[i].time.end,
                timeId: data.dates[i].time.id
            })
        }
        state.dataEntryUpdate = data;
    },
    SET_SAVE_TIME_SHEET(state, value){
        state.loadingSaveTimeSheet = value;
    },
    SET_LOADING_BREAKS_TYPES(state, value){
        state.loadingBreaksTypes = value;
    },
    RESET_DATA_ENTRY_UPDATE(state){
        state.days = [];
    }
}