import Vue from 'vue';

export default {
    INIT_HEADERS(state, date) {
        state.headers = date;
    },
    ADD_HEADER(state, data) {
        if (state.headers.find(e => e.key == data.key) == undefined) {
            state.headers.push(data);
            Vue.set(state.headers, data, state.headers.length - 1);
            localStorage.setItem("workers-headers", JSON.stringify(state.headers))
        }
    },
    REMOVE_HEADER(state, value) {
        let index = state.headers.indexOf(state.headers.find(e => e.key == value));
        if (index != -1) {
            state.headers.splice(index, 1);
            localStorage.setItem("workers-headers", JSON.stringify(state.headers))
        }
    },
    SET_LOADING_ITEMS(state, value){
        state.loading = value;
    },
    RESET_LIST(state){
        state.items = [];
    },
    SET_ITEMS(state,data){
        state.items = data.workers;
        state.skills = data.skills;
    },
    SET_META(state,data){
        state.meta.current_page = data.current_page;
        state.meta.last_page = data.last_page;
        state.meta.total = data.total;
    },
    SET_LOADING_ITEM_DETAILS(state, value){
        state.loadingSkills = value;
    },
    SET_ITEM_DETAILS(state,data){
        state.details = data;
    },
    SET_LOADING_BASIC_INFO(state, value){
        state.loadingBasicInformation = value;
    },
    SET_BASIC_INFO(state,data){
        state.basicInformation = data;
    },
    SET_LOADING_ACTIVITY(state, value){
        state.loadingActivity = value;
    },
    SET_ACTIVITY(state,data){
        state.activity = data;
    },
    SET_LOADING_REVIEWS(state, value){
        state.loadingReviews = value;
    },
    SET_REVIEWS(state,data){
        state.reviews = data;
    },
    SET_LOADING_WORK_HISTORY(state, value){
        state.loadingWorkHistory = value;
    },
    SET_WORK_HISTORY(state,data){
        state.workHistory = data.data;

        state.metaWorkHistory.current_page = data.meta.current_page;
        state.metaWorkHistory.last_page = data.meta.last_page;
        state.metaWorkHistory.total = data.meta.total;
    },
    SET_LOADING_EMPLOYMENT_HISTORY(state, value){
        state.loadingEmploymentHistory = value;
    },
    SET_EMPLOYMENT_HISTORY(state,data){
        state.employmentHistory = data;
    },
}