import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';

export const initialState = {
    loading: false,
    headers: [
        { key: 'actions', label: '', stickyColumn: true, canAdmin: true },
        { key: 'name', label: 'Name', sortable: true, stickyColumn: true },
        { key: 'airtable_project_id', label: 'Work Order #', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'workers', label: 'Workers', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true }
    ],
    items:[],
    itemsAssign:[],
    companies:[],
    statuses:[],
    meta:{
        current_page:1,
        last_page:1,
        total:0
    },
    //Create or edit work order
    newWorkOrder:null,
    positions:null,
    job_contact:null,
    billing:null,
    loadingParams:false,
    params:{},
    branches:[],
    loadingNewWorkOrder:false,
    workOrderId:null,
    loadingCreatePosition:false,
    loadingContact:false,
    loadingBilling:false,
    loadingFiles:false,
    files:null,
    workOrder:{},
    loadingWorkOrder:true,
    //details
    loadingDetails:false,
    details:{},
    loadingGeneralInfo: false,
    generalInfo:{},
    loadingHistory: false,
    history:{},
    loadingContacts:false,
    contacts:{},
    loadingBillingDetails:false,
    billingDetails:{}
};

const applicants = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    mutations,
    actions
};

export default applicants;