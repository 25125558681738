//import Vue from 'vue';

export default {
    SET_LOADING_CITIES(state, value){
        state.loadingCities = value;
    },
    SET_LOADING_ZIP_CODES(state, value){
        state.loadingZipCodes = value;
    },
    SET_LOADING_COMPANY_LIST(state, value){
        state.loadingCompanies = value;
    },
    SET_LOADING_WORK_ORDERS_LIST(state, value){
        state.loadingWorkOrders= value;
    },
    SET_LOADING_WORKERS_LIST(state, value){
        state.loadingWorkers= value;
    },
    SET_CITIES(state,data){
        state.cities = data;
    },
    SET_ZIP_CODES(state,data){
        state.zipCodes = data;
    },
    SET_COMPANY_LIST(state,data){
        state.companies = data;
    },
    SET_WORK_ORDERS_LIST(state,data){
        state.workOrders = data;
    },
    SET_WORKERS_LIST(state,data){
        state.workers = data;
    },
}