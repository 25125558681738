import Vue from 'vue';
import axios from 'axios';
import router from '@/router';

export default { 
    //headers
    initHeaders(context) {
        let workersHeaders = JSON.parse(localStorage.getItem('time-sheet-headers'));
        if (workersHeaders != null)
            context.commit('INIT_HEADERS', workersHeaders);

    },
    addHeader(context, data) {
        const { key, label } = data;
        let newHeader = {
            key: key,
            label: label,
            sortable: true
        };
        context.commit('ADD_HEADER', newHeader);
    },
    removeHeader(context, data) {
        const { key } = data;
        context.commit('REMOVE_HEADER', key);
    },
    async getList(context, data){
        context.commit('SET_LOADING_LIST', true);

        if (data.resetList) context.dispatch('resetList');
        const { filters, page, paginate ,name,sorting} = data;
        let allFilters = {}
        allFilters.paginate = paginate;
        allFilters.filters = Object.assign({}, filters);
        if(sorting.name != null){
            allFilters.sorting = Object.assign({}, sorting);
            allFilters.sorting.name = allFilters.sorting.name == 'dates' ? 'date' : allFilters.sorting.name == 'verified' 
                ? 'verify' : allFilters.sorting.name == 'updated_at' ? 'last_update' : allFilters.sorting.name;
            allFilters.sorting.ASC = !allFilters.sorting.ASC;

        }
        if(filters.date != undefined)
            delete allFilters.filters.date;
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/worker/data-entry/list?page=${page}`,allFilters)
        .then(result => {
            context.commit('SET_LIST', result.data.data);
            context.commit('SET_META', result.data.meta);
            context.commit('SET_LOADING_LIST', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_LIST', false);
        });
    },
    resetList(context){
        context.commit('RESET_LIST');
    },
    async getWorkOrdersListForWorker(context, id){
        context.commit('SET_LOADING_WORK_ORDERS', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/worker/${id}/work-orders/list`)
        .then( result => {
            context.commit('SET_WORK_ORDERS', result.data.data);
            context.commit('SET_LOADING_WORK_ORDERS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORK_ORDERS', false );
        });
    },
    async getBreaksTypes(context){
        context.commit('SET_LOADING_BREAKS_TYPES', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/worker/breaks-types/list`)
        .then( result => {            
            context.commit('SET_BREAKS_TYPES', result.data);
            context.commit('SET_LOADING_BREAKS_TYPES', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_BREAKS_TYPES', false);
        });
    },
    async createTimeSheet(context, data){
        context.commit('SET_SAVE_TIME_SHEET', true);
        const vm = new Vue();
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/worker/data-entry/save`,data, { headers: { 'Content-Type': 'multipart/form-data'}})
        .then( result => {
            if(result.data.message != undefined)
                vm.$toasted.success(result.data.message);     
            context.commit('SET_SAVE_TIME_SHEET', false); 
            if(localStorage.getItem('accessToken') != null)   
                router.push({ path: '/worker/data-entry' });   
            else  
                router.push({ path: '/' });       
        })
        .catch(() => {          
            context.commit('SET_SAVE_TIME_SHEET', false);       
        });
    },
    async updateTimeSheet(context, data){
        context.commit('SET_SAVE_TIME_SHEET', true);  
        const vm = new Vue();
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/worker/data-entry/${data.id}/update`,data.data, { headers: { 'Content-Type': 'multipart/form-data'}})
        .then( result => {   
            if(result.data.message != undefined)
                vm.$toasted.success(result.data.message); 
            context.commit('SET_SAVE_TIME_SHEET', false);  
            if(localStorage.getItem('accessToken') != null)   
                router.push({ path: '/worker/data-entry' });   
            else  
                router.push({ path: '/' });       
        })
        .catch(() => {          
            context.commit('SET_SAVE_TIME_SHEET', false);       
        });
    },
    async checkOnFilledDataForTheSelectedDate(context, data){
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/worker/${data.id}/check-date-on-filled-data?date=${data.date}`)
        .then( result => {
            context.commit('SET_CHECK_DATE', result.data.data);
        })
        .catch(() => {
        });
    },
    async deleteTimeSheet(context, id) {
        const vm = new Vue();
        await axios
            .delete(`${process.env.VUE_APP_API_URL}/api/v1/worker/data-entry/${id}/delete`)
            .then(result => {
                if (result.data.status) {
                    router.push({name: 'DataEntry'});
                    vm.$toasted.success(result.data.message);
                } else {
                    vm.$toasted.error(result.data.message);
                }
            })
            .catch(() => {
            });
    },
    async getDataEntryUpdate(context, id){
        context.commit('SET_DATA_ENTRY_UPDATE_LOADING', true);
        context.commit('RESET_DATA_ENTRY_UPDATE');
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/worker/data-entry/${id}/edit`)
        .then( result => {
            context.commit('SET_DATA_ENTRY_UPDATE', result.data.data);
            context.commit('SET_DATA_ENTRY_UPDATE_LOADING', false);
        })
        .catch(() => {
            context.commit('SET_DATA_ENTRY_UPDATE_LOADING', false );
        });
    },
}
