import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';

export const initialState = {
    loading: false,
    headers: [
        { key: 'actions', label: '', stickyColumn: true },
        { key: 'name', label: 'Name', sortable: true, stickyColumn: true },
        { key: 'positionApplied', label: 'Position applied', sortable: true },
        { key: 'applied', label: 'Applied', sortable: true },
        { key: 'contacts', label: 'Contacts', sortable: true },
        { key: 'resumeUpdateOn', label: 'Resume, Update on', sortable: true },
        { key: 'skills', label: 'Skills' },
        { key: 'stage', label: 'Stage', sortable: true }
    ],
    items:[],
    degrees:[],
    files_type:{},
    interviewResults:[],
    jobApplicantStatus:[],
    levels:[],
    recruiters:[],
    skills:[],
    stages:[],
    states:[],
    subCategories:{},
    tools:{},
    loadingSkills:false,
    userSkills:[],
    loadingStagesHistory:false,
    stagesHistory:[],
    meta:{
        current_page:1,
        last_page:1,
        total:0
    },
    workordersLoading:false,
    workorders:{},
    //details
    loadingDetails:false,
    details: {},
    loadingBasicInformation:true,
    basicInformation: {},
    loadingActivity: false,
    activity:{},
    loadingReviews: true,
    reviews:{},
    loadingApplicantStage: true,
    applicantStage:{}
};

const applicants = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    mutations,
    actions
};

export default applicants;