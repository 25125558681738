import Vue from 'vue';
import App from './App.vue';
import router from './router'
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import './assets/css/app.scss';

require('@/configs/bootstrap-config.js');
require('@/configs/components-config.js');
require('@/configs/axios-config.js');

import store from './store';
Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
locale.use(lang);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')