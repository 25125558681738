import moment from 'moment';

let exports = {};

exports.getTimeString = (value) => {
    return Math.trunc(value / 60) + ':' + (value % 60 < 10 ? '0' + value % 60 : value % 60);
},
exports.getDiffMinutes = (startDate, endDate) => {
    let start = moment(startDate);
    let end = moment(endDate);
    return end.diff(start, 'minutes', true);
},
exports.getBreakDiffMinutes = (startBreak, endBreak) => {
    let start = moment(startBreak, 'HH:mm');
    let end = moment(endBreak, 'HH:mm');
    return end.isAfter(start)
        ? end.diff(start, 'minutes', true)
        : moment('24:00', 'HH:mm').diff(start, 'minutes', true)
        + moment(endBreak, 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes', true);
},
exports.getDiffHours = (startDate, endDate) => {
    let start = moment(startDate);
    let end = moment(endDate);
    return end.diff(start, 'hours', true);
},
exports.changeColonToDot = (value) => {
    return parseFloat(value.toString().replace(":", "."))
}
exports.isFutureDay = (day) => {
    return moment(day).isAfter(new Date(), 'day')
}
exports.checkCrossingHoursInSingleDay = (day, filledDays, index) => {
    for (let i = 0; i < filledDays.length; i++) {
        if (i !== index && day.dateStart === filledDays[i].dateStart)
        {
            let startAfterStart = moment(day.timeStart, 'HH:mm').isSameOrAfter(moment(filledDays[i].timeStart, 'HH:mm'));
            let startBeforeEnd = moment(day.timeStart, 'HH:mm').isSameOrBefore(moment(filledDays[i].timeEnd, 'HH:mm'));
            let endAfterStart = moment(day.timeEnd, 'HH:mm').isSameOrAfter(moment(filledDays[i].timeStart, 'HH:mm'));
            let endBeforeEnd = moment(day.timeEnd, 'HH:mm').isSameOrBefore(moment(filledDays[i].timeEnd, 'HH:mm'));
            if( (startAfterStart && startBeforeEnd) || (endAfterStart && endBeforeEnd) ){
                return true
            }
        }
    }
    return false;
}
exports.checkBreakOnWithinDate = (day) => {
    let start = moment('00:00', 'HH:mm');
    let end = moment('24:00', 'HH:mm');
    let startWork = moment(day.dateStart + ' '+ day.timeStart, 'YYYY-MM-DD HH:mm');
    let endWork = moment(day.dateEnd  + ' '+ day.timeEnd, 'YYYY-MM-DD HH:mm');
    let dayStart = moment(day.timeStart, 'HH:mm');
    let dayEnd = moment(day.timeEnd, 'HH:mm');
    let error = false;
    for (let j = 0; j < day.breaks.length; j++) {
        if (day.breaks[j].timeStart != null && day.breaks[j].timeEnd != null) {
            var startBreak = setWorkingTimeProperties(day.dateStart, day.breaks[j].timeStart);
            var endBreak = setWorkingTimeProperties(day.dateStart, day.breaks[j].timeEnd);

            if (
                moment(day.breaks[j].timeStart, 'HH:mm').isBetween(dayStart, end, undefined, '()')
                &&
                moment(day.breaks[j].timeEnd, 'HH:mm').isBetween(dayStart, end, undefined, '(]')
            ) {
                //the single day or the first day of the nightshift
                startBreak = setWorkingTimeProperties(day.dateStart, day.breaks[j].timeStart);
                endBreak = setWorkingTimeProperties(day.dateStart, day.breaks[j].timeEnd);
            } else if (
                moment(day.breaks[j].timeStart, 'HH:mm').isBetween(start, dayEnd, undefined, '[)')
                &&
                moment(day.breaks[j].timeEnd, 'HH:mm').isBetween(start, dayEnd, undefined, '()')
            ) {
                //the second day of the nightshift
                startBreak = setWorkingTimeProperties(day.dateEnd, day.breaks[j].timeStart);
                endBreak = setWorkingTimeProperties(day.dateEnd, day.breaks[j].timeEnd);
            } else if (
                moment(day.breaks[j].timeStart, 'HH:mm').isBetween(dayStart, end, undefined, '(]')
                &&
                moment(day.breaks[j].timeEnd, 'HH:mm').isBetween(start, dayEnd, undefined, '[)')
            ) {
                //different days of the nightshift
                startBreak = setWorkingTimeProperties(day.dateStart, day.breaks[j].timeStart);
                endBreak = setWorkingTimeProperties(day.dateEnd, day.breaks[j].timeEnd);
            }
            error = error ? error : (!moment(startBreak, 'HH:mm').isBetween(startWork, endWork) ||
                !moment(endBreak, 'HH:mm').isBetween(startWork, endWork));
        }
    }
    return error;
}
function setWorkingTimeProperties(day, time){
    return moment(day + ' '+ time, 'YYYY-MM-DD HH:mm')
}

exports.checkBreakForCoincidenceWithOthers = (day) => {
    for (let i = 0; i < day.breaks.length; i++) {
        let start = moment(day.breaks[i].timeStart, 'HH:mm');
        let end = moment(day.breaks[i].timeEnd, 'HH:mm');
        for (let j = 0; j < day.breaks.length; j++) {
            if(i != j){
                if( moment(day.breaks[j].timeStart, 'HH:mm').isBetween(start, end, null, '[]')
                    || moment(day.breaks[j].timeEnd, 'HH:mm').isBetween(start, end, null, '[]'))
                    return true;
            }
        }
    }
    return false;
},
exports.isIntersectionOfTimeInOneDate = (day, days, index) => {
    let dayStart = moment(day.timeStart, 'HH:mm');
    let dayEnd = moment(day.timeEnd, 'HH:mm');
    for (let i = 0; i < days.length; i++) {
        if(i != index){
            if(moment(days[i].timeStart, 'HH:mm').isBetween(dayStart, dayEnd) ||
                moment(days[i].timeEnd, 'HH:mm').isBetween(dayStart, dayEnd))
                return true;
        }
    }
    return false;
},
exports.getCountDate = (day, days, index) => {
    let count = 0;
    for (let i = 0; i < days.length; i++) {
        if(i != index){
            if(days[i].dateStart == day.dateStart)
                count++;
        }
    }
    return count;
}


export default exports;