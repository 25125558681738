const SUPERADMIN = 1;
const ADMIN = 2;
const DATA_ENTRY_OPERATOR = 3;
const EMPLOYEE = 4;
const RECRUITER = 6;
const WORKER = 8;

export default {
    SUPERADMIN: SUPERADMIN,
    ADMIN: ADMIN,
    DATA_ENTRY_OPERATOR: DATA_ENTRY_OPERATOR,
    EMPLOYEE: EMPLOYEE,
    WORKER: WORKER,
    RECRUITER: RECRUITER
};