<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  
  computed: {
    layout(){
      // localStorage.clear();
      const token = localStorage.getItem('accessToken');
      if(this.$route.meta.unlogged === true && token == null){
        return 'empty-layout';
      }
      return this.$route.name == 'DataEntryCreate' &&  token == null ? 'empty-layout' : this.$route.meta.layout || "default-layout" 
    }
  }
};
</script>

<style>
/*.overflow-hide {
  overflow: hidden;
} */
</style>
