import _ from 'lodash';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const initialState = {
    headers: [
        { key: 'actions', label: '', stickyColumn: true },
        { key: 'dates', label: 'Dates', sortable: true },
        { key: 'company', label: 'Company', sortable: true},
        { key: 'user', label: 'User', sortable: true },
        { key: 'verified', label: 'Verified', sortable: true },
        { key: 'notes', label: 'Notes'},
        { key: 'updated_at', label: 'Last update at', sortable: true }
    ],
    items:[],
    meta:{
        current_page:1,
        last_page:1,
        total:0
    },
    breaksTypes:[],
    checkDate:[],
    dataEntryUpdate: {
        company: null,
        user_name: '',
        user_email: '',
        notes: '',
        user_location:'',
        user_position:'',
        file:null,
    },
    worker:null,
    workOrder:null,
    workOrders: [],
    days: [{
        errors: [],
        breaks:[],
        dateStart:null,
        dateEnd:null,
        timeStart: null,
        timeEnd:null,
        checkDate: null
    }],
    //loading
    loading:false,
    loadingSaveTimeSheet: false,
    loadingBreaksTypes: false,
    loadingWorkOrders:false,
    dataEntryUpdateLoading: false,
    
    //delete
    dataToDelete:{
        days:[],
        breaks:[],
    },

    //page
    isUpdate: false,
    privatePage: false,
    isValidateRecaptcha: false
};

const timeSheet = {
    namespaced: true,
    state: _.cloneDeep(initialState),
    getters,
    mutations,
    actions
};

export default timeSheet;