import axios from 'axios';
import router from '../../router/index.js';

export default {
    //headers
    initHeaders(context) {
        let workersHeaders = JSON.parse(localStorage.getItem('workers-headers'));
        if (workersHeaders != null)
            context.commit('INIT_HEADERS', workersHeaders);

    },
    addHeader(context, data) {
        const { key, label } = data;
        let newHeader = {
            key: key,
            label: label,
            sortable: true
        };
        context.commit('ADD_HEADER', newHeader);
    },
    removeHeader(context, data) {
        const { key } = data;
        context.commit('REMOVE_HEADER', key);
    },
    //workOrders
    async getList(context, data){
        context.commit('SET_LOADING_ITEMS', true);
            
        if (data.resetList) context.dispatch('resetList');
        const { filters, page, paginate ,name,sorting} = data;
        
        filters.paginate = paginate;
 
        if(name.length != 0)
            filters.name = name;
        
        if(sorting.name != null){
            filters.sorting = Object.assign({}, sorting);
            filters.sorting.ASC = !filters.sorting.ASC;    
        }
        
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workers/list?page=${page}`,filters)
        .then(result => {
            context.commit('SET_ITEMS', result.data.data);
            context.commit('SET_META', result.data.meta);
            context.commit('SET_LOADING_ITEMS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_ITEMS', false );
        });
    },
    resetList(context){
        context.commit('RESET_LIST');
    },
    //Details
    async details(context, id){
        context.commit('SET_LOADING_ITEM_DETAILS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/details`)
        .then(result => {
            context.commit('SET_ITEM_DETAILS', result.data.data);
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        })
        .catch(() => {
            router.push({ path: '/workers' });
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        });
    },
    async basicInfo(context, id){
        context.commit('SET_LOADING_BASIC_INFO', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/basic-info`)
        .then(result => {
            context.commit('SET_BASIC_INFO', result.data.data);
            context.commit('SET_LOADING_BASIC_INFO', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_BASIC_INFO', false);
        });
    },
    async activity(context, id){
        context.commit('SET_LOADING_ACTIVITY', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/activity`)
        .then(result => {
            context.commit('SET_ACTIVITY', result.data.data);
            context.commit('SET_LOADING_ACTIVITY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_ACTIVITY', false);
        });
    },
    async reviews(context, id){
        context.commit('SET_LOADING_REVIEWS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/reviews`)
        .then(result => {
            context.commit('SET_REVIEWS', result.data.data);
            context.commit('SET_LOADING_REVIEWS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_REVIEWS', false);
        });
    },
    async addComment(context, data){
        const { note, id} = data;
        
        context.commit('SET_LOADING_REVIEWS', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/reviews/add`, {note:note})
        .then(() => {
            context.dispatch('reviews',id);
        })
        .catch(() => {
            context.commit('SET_LOADING_REVIEWS', false);
        });
    },
    async workHistory(context, data){
        context.commit('SET_LOADING_WORK_HISTORY', true);

        const {page, paginate, id} = data;
        let filters = {};
        filters.paginate = paginate > 0 ? paginate: 10;
        if(data.filters.month != null)
            filters.month = data.filters.month;        
        if(data.filters.year != null)
            filters.year = data.filters.year;

        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/work-history?page=${page}`,filters)
        .then(result => {
            context.commit('SET_WORK_HISTORY', result.data);
            context.commit('SET_LOADING_WORK_HISTORY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORK_HISTORY', false);
        });
    },
    async employmentHistory(context, id){
        
        context.commit('SET_LOADING_EMPLOYMENT_HISTORY', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workers/${id}/employment-history`)
        .then(result => {
            context.commit('SET_EMPLOYMENT_HISTORY', result.data.data);
            context.commit('SET_LOADING_EMPLOYMENT_HISTORY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_EMPLOYMENT_HISTORY', false);
        });
    }
};