import Vue from 'vue';

export default {
    INIT_HEADERS(state, date) {
        state.headers = date;
    },
    ADD_HEADER(state, data) {
        if (state.headers.find(e => e.key == data.key) == undefined) {
            state.headers.push(data);
            Vue.set(state.headers, data, state.headers.length - 1);
            localStorage.setItem("applicants-headers", JSON.stringify(state.headers))
        }
    },
    REMOVE_HEADER(state, value) {
        let index = state.headers.indexOf(state.headers.find(e => e.key == value));
        if (index != -1) {
            state.headers.splice(index, 1);
            localStorage.setItem("applicants-headers", JSON.stringify(state.headers))
        }
    },
    SET_LOADING_ITEMS(state, value){
        state.loading = value;
    },
    RESET_LIST(state){
        state.items = [];
    },
    SET_ITEMS(state,data){
        state.items = data.applicants;
        state.degrees = data.degrees;
        state.files_type = data.files_type;
        state.interviewResults = data.interviewResults;
        state.jobApplicantStatus = data.jobApplicantStatus;
        state.levels = data.levels;
        state.recruiters = data.recruiters;
        state.skills = data.skills;
        state.stages = data.stages;
        state.states = data.states;
        state.subCategories = data.subCategories;
        state.tools = data.tools;
    },
    SET_META(state,data){
        state.meta.current_page = data.current_page;
        state.meta.last_page = data.last_page;
        state.meta.total = data.total;
    },
    SET_LOADING_SKILLS(state, value){
        state.loadingSkills = value;
    },
    SET_USER_SKILLS(state,data){
        state.userSkills = data;
    },
    SET_LOADING_STAGES_HISTORY(state, value){
        state.loadingStagesHistory = value;
    },
    SET_STAGES_HISTORY(state,data){
        state.stagesHistory = data;
    },
    SET_LOADING_WORKORDER(state,value){
        state.workordersLoading = value;
    },
    SET_WORKORDER(state,data){
        state.workorders = data;
    },
    SET_LOADING_ITEM_DETAILS(state, value){
        state.loadingDetails = value;
    },
    SET_ITEM_DETAILS(state,data){
        state.details = data;
    },
    SET_ITEM_DETAILS_OTHERS(state,data){
        state.degrees = data.degrees;
        state.files_type = data.files_type;
        state.interviewResults = data.interviewResults;
        state.jobApplicantStatus = data.jobApplicantStatus;
        state.levels = data.applicant.levels;
        state.recruiters = data.recruiters;
        state.skills = data.skills;
        state.stages = data.stages;
        state.states = data.location.states;
        state.subCategories = data.subCategories;
        state.tools = data.tools;
    },
    SET_LOADING_BASIC_INFO(state, value){
        state.loadingBasicInformation = value;
    },
    SET_BASIC_INFO(state,data){
        state.basicInformation = data;
    },
    SET_LOADING_ACTIVITY(state, value){
        state.loadingActivity = value;
    },
    SET_ACTIVITY(state,data){
        state.activity = data;
    },
    SET_LOADING_REVIEWS(state, value){
        state.loadingReviews = value;
    },
    SET_REVIEWS(state,data){
        state.reviews = data;
    },
    SET_LOADING_APPLICANT_STAGE(state, value){
        state.loadingApplicantStage = value;
    },
    SET_APPLICANT_STAGE(state,data){
        state.applicantStage = data;
    },
}