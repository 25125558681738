export default {
    SET_LOADING_CURRENT_USER(state, value) {
        state.loadingCurrentUser = value;
    },
    SET_CURRENT_USER(state, data) {
        state.currentUser = data;
    },
    SET_UNLOGGED_DATA(state, data) {
        state.unlogged = data;
    },
    SET_UNLOGGED_INIT_DATA(state) {
        state.unlogged = {
            activate: false,
            reset: false,
            status: false,
            message: ''
        };
    }
}