import Vue from 'vue';
import axios from 'axios';
import router from '../../router/index.js';

export default {
    //headers
    initHeaders(context) {
        let applicantsHeaders = JSON.parse(localStorage.getItem('applicants-headers'));
        if (applicantsHeaders != null)
            context.commit('INIT_HEADERS', applicantsHeaders);

    },
    addHeader(context, data) {
        const { key, label } = data;
        let newHeader = {
            key: key,
            label: label,
            sortable: true
        };
        context.commit('ADD_HEADER', newHeader);
    },
    removeHeader(context, data) {
        const { key } = data;
        context.commit('REMOVE_HEADER', key);
    },
    //applicants
    async getList(context, data){
        context.commit('SET_LOADING_ITEMS', true);
        const { filters, page, paginate, sorting,name,stage} = data;
        
        filters.paginate = paginate;
        
        if(name.length != 0)
            filters.name = name;
        if(stage.length != 0)
            filters.stage = stage;
        if(sorting.name != null){
            filters.sorting = Object.assign({}, sorting);
            filters.sorting.name = filters.sorting.name == 'positionApplied' ? 'position' : filters.sorting.name == 'contacts' 
                ? 'contact' : filters.sorting.name == 'resumeUpdateOn' ? 'resume' : filters.sorting.name == 'applied' ? 'apply_date' : filters.sorting.name;
            filters.sorting.ASC = !filters.sorting.ASC;

        }
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/applicants/list?page=${page}`, filters)
        .then(result => {
            context.commit('SET_ITEMS', result.data.data);
            context.commit('SET_META', result.data.meta);
            context.commit('SET_LOADING_ITEMS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_ITEMS', false );
        });
    },
    async getUserSkills(context, id){
        context.commit('SET_LOADING_SKILLS', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicants/${id}/tags`)
        .then(result => {
            context.commit('SET_USER_SKILLS', result.data.data.skills);
            context.commit('SET_LOADING_SKILLS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_SKILLS', false );
        });
    },
    async getUserStagesHistory(context, id){
        context.commit('SET_LOADING_STAGES_HISTORY', true);

        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicants/${id}/stages-history`)
        .then(result => {
            context.commit('SET_STAGES_HISTORY', result.data.data);
            context.commit('SET_LOADING_STAGES_HISTORY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_STAGES_HISTORY', false );
        });
    },
    async addItem(context, data){
        const vm = new Vue();
        context.commit('SET_LOADING_ITEMS', true);
        const { user, filters} = data;
        let userData = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone : user.phone,
            phone2 : user.phone2,
            stage_id : user.stage,
            address : user.address.address,
            state : user.address.state.id,
            address_id : user.address.id,
            city : user.address.city.id,
            zipcode : user.address.zipcode.id
        };
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/applicants/store`, userData)
        .then(result => {              
            if(route.name != 'ApplicantDetails')   
                context.dispatch('getList',filters);
            else
                context.dispatch('currentUserDetails');
            if(result.data.message != undefined)
                vm.$toasted.success(result.data.message);
        })
        .catch(() => {
            context.commit('SET_LOADING_ITEMS', false);
        });
    },
    async updateItem(context, data){
        const vm = new Vue();
        context.commit('SET_LOADING_ITEMS', true);
        const { user, filters} = data;
        let userData = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone : user.phone,
            phone2 : user.phone2,
            stage_id : user.stage,
            address : user.address.address,
            state : user.address.state.id,
            address_id : user.address.id,
            city : user.address.city.id,
            zipcode : user.address.zipcode.id,
            experiences :{
                title: user.experience.title,
                level: user.experience.type_employment,
                years_of_experiences: user.experience.years_of_experiences
            }
        };
        await axios
        .put(`${process.env.VUE_APP_API_URL}/api/v1/applicants/${user.id}/update`, userData)
        .then(result => {
            context.commit('SET_LOADING_ITEMS', false);
            if(data.routeName != 'ApplicantDetails')   
                context.dispatch('getList', filters);
            else
                context.dispatch('currentUserDetails');
            if(result.data.message != undefined)
                vm.$toasted.success(result.data.message);
        })
        .catch(() => {
            context.commit('SET_LOADING_ITEMS', false);
        });
    },
    async applicantsAddToWorkorder(context, data){
        const vm = new Vue();
        context.commit('SET_LOADING_WORKORDER', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/applicants/applicant-add-to-workorder/`, data)
        .then(result => {
            context.commit('SET_WORKORDER', result.data.data);
            context.commit('SET_LOADING_WORKORDER', false);
            if(result.data.message != undefined)
                vm.$toasted.success(result.data.message);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORKORDER', false);
        });
    },
    async addWorkOrderUsersToWorkOrder(context, data){
        context.commit('SET_LOADING_WORKORDER', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${data.id}/workers/add`, data.newWorkOrder)
        .then(() => {
            context.commit('SET_LOADING_WORKORDER', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORKORDER', false);
        });
    },
    //Details
    async details(context, id){
        context.commit('SET_LOADING_ITEM_DETAILS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/details`)
        .then(result => {
            context.commit('SET_ITEM_DETAILS', result.data.data);
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        })
        .catch(() => {
            router.push({ path: '/applicants' });
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        });
    },
    async currentUserDetails(context){
        context.commit('SET_LOADING_ITEM_DETAILS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicants/user/applicant-details`)
        .then(result => {
            context.commit('SET_ITEM_DETAILS', result.data.data);
            context.commit('SET_LOADING_ITEM_DETAILS', false);
            if(result.data.data.applicant != undefined){
                context.commit('SET_ITEM_DETAILS_OTHERS', result.data.data);
                context.dispatch('basicInfo', result.data.data.applicant.id);
                context.dispatch('reviews', result.data.data.applicant.id);
            }
        })
        .catch(() => {
            router.push({ path: '/worker/data-entry' });
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        });
    },
    async basicInfo(context, id){
        context.commit('SET_LOADING_BASIC_INFO', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/basic-info`)
        .then(result => {
            context.commit('SET_BASIC_INFO', result.data.data);
            context.commit('SET_LOADING_BASIC_INFO', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_BASIC_INFO', false);
        });
    },
    async activity(context, id){
        context.commit('SET_LOADING_ACTIVITY', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/activity`)
        .then(result => {
            context.commit('SET_ACTIVITY', result.data.data);
            context.commit('SET_LOADING_ACTIVITY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_ACTIVITY', false);
        });
    },
    async applicantStage(context, id){
        context.commit('SET_LOADING_APPLICANT_STAGE', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/applicant-stage`)
        .then(result => {
            context.commit('SET_APPLICANT_STAGE', result.data.data);
            context.commit('SET_LOADING_APPLICANT_STAGE', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_APPLICANT_STAGE', false);
        });
    },
    async reviews(context, id){
        context.commit('SET_LOADING_REVIEWS', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/reviews`)
        .then(result => {
            context.commit('SET_REVIEWS', result.data.data);
            context.commit('SET_LOADING_REVIEWS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_REVIEWS', false);
        });
    },
    async addComment(context, data){
        const { note, id} = data;
        
        context.commit('SET_LOADING_REVIEWS', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/applicant/${id}/reviews/add`, {note:note})
        .then(() => {
            context.dispatch('reviews',id);
        })
        .catch(() => {
            context.commit('SET_LOADING_REVIEWS', false);
        });
    }
};