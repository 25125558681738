import Vue from 'vue';
import axios from 'axios';
import router from '../../router/index.js';

export default {
    //headers
    initHeaders(context) {
        let workOrdersHeaders = JSON.parse(localStorage.getItem('work-orders-headers'));
        if (workOrdersHeaders != null)
            context.commit('INIT_HEADERS', workOrdersHeaders);

    },
    addHeader(context, data) {
        const { key, label } = data;
        let newHeader = {
            key: key,
            label: label,
            sortable: true
        };
        context.commit('ADD_HEADER', newHeader);
    },
    removeHeader(context, data) {
        const { key } = data;
        context.commit('REMOVE_HEADER', key);
    },
    //workOrders
    async getList(context, data){
        context.commit('SET_LOADING_ITEMS', true);
        let getListRoute = data.isAdmin ? '/api/v1/workorders/list' : '/api/v1/workorders/list-for-worker'
        let page = data == undefined || data.page == undefined ? 1 : data.page;
        let filtersList = {}
        if(data != undefined){
            filtersList = data.filters == undefined ? {} : data.filters;
            filtersList.pagitane = data.paginate == undefined ? 10 : data.paginate;
            filtersList.search = data.name == undefined || data.name.length == 0? null : data.name;
            filtersList.sorting = data.sorting.name == null || data.sorting.ASC == null ? null : data.sorting;
        }
        if(data.sorting.name != null){
            filtersList.sorting = Object.assign({}, data.sorting);
            filtersList.sorting.name = filtersList.sorting.name == 'name' ? 'title' : filtersList.sorting.name;
            filtersList.sorting.ASC = !filtersList.sorting.ASC;  

        }
        context.dispatch('resetList');
        const vm = new Vue();
        await axios
        .post(`${process.env.VUE_APP_API_URL}${getListRoute}?page=${page}`, filtersList)
        .then(result => {
            context.commit(data != undefined && data.isAssignWorkers? 'SET_ITEMS_ASSIGN' : 'SET_ITEMS', result.data.data);
            context.commit('SET_META', result.data.meta);
            context.commit('SET_LOADING_ITEMS', false);
        })
        .catch(err => {
            context.commit('SET_LOADING_ITEMS', false );
            this.$toasted.error('Error trying to get list applicants: ' + err); 
        });
    },
    resetList(context){
        context.commit('RESET_LIST');
    },
    //details
    async jobInformation(context, id){
        context.commit('SET_LOADING_ITEM_DETAILS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/details`)
        .then(result => {
            context.commit('SET_ITEM_DETAILS', result.data.data);
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        })
        .catch(() => {
            router.push({ path: '/workorders' });
            context.commit('SET_LOADING_ITEM_DETAILS', false);
        });
    },
    async generalInfo(context, id){
        context.commit('SET_LOADING_GENERAL_INFO', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/general-info`)
        .then(result => {
            context.commit('SET_GENERAL_INFO', result.data.data);
            context.commit('SET_LOADING_GENERAL_INFO', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_GENERAL_INFO', false);
        });
    },
    async history(context, id){
        context.commit('SET_LOADING_HISTORY', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/history`)
        .then(result => {
            context.commit('SET_HISTORY', result.data.data);
            context.commit('SET_LOADING_HISTORY', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_HISTORY', false);
        });
    },
    async contacts(context, id){
        context.commit('SET_LOADING_CONTACTS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/contacts`)
        .then(result => {
            context.commit('SET_CONTACTS', result.data.data);
            context.commit('SET_LOADING_CONTACTS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_CONTACTS', false);
        });
    },
    async billing(context, id){
        context.commit('SET_LOADING_BILLING_DETAILS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/billing`)
        .then(result => {
            context.commit('SET_BILLING_DETAILS', result.data.data);
            context.commit('SET_LOADING_BILLING_DETAILS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_BILLING', false);
        });
    },

    //dublicate
    async createDuplicate(context, id){
        const vm = new Vue();
        vm.$toasted.success('Creating ...');  
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/dublicate`)
        .then(() => {            
            context.dispatch('getList');
            vm.$toasted.success('Duplicate created successfully');  
        })
        .catch(() => {});
    },
    //create and update work order
    async getParamsForStore(context){
        context.commit('SET_LOADING_PARAMS', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorders/params-for-store`)
        .then(result => {
            context.commit('SET_PARAMS', result.data);
            context.commit('SET_LOADING_PARAMS', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_PARAMS', false);
        });
    },
    async branchesByCompanyId(context,id){
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/company/${id}/branches`)
        .then(result => {
            context.commit('SET_BRANCHES', result.data.data);
        })
        .catch(() => {});
    },
    async newWorkOrder(context,data){  
        const vm = new Vue();     
        context.commit('SET_LOADING_NEW_WORK_ORDER', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/store`,data)
        .then(result => {
            context.commit('SET_NEW_WORK_ORDER', result.data.data);
            context.commit('SET_LOADING_NEW_WORK_ORDER', false);
            vm.$toasted.success('Work order were saved successfully');  
        })
        .catch(() => {
            context.commit('SET_LOADING_NEW_WORK_ORDER', false);
        });
    },
    async createManyPosition(context,data){
        context.commit('SET_LOADING_CREATE_POSITION', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${data.id}/positions/create-many`,{positions: data.positions})
        .then(() => {
            context.commit('SET_LOADING_CREATE_POSITION', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_CREATE_POSITION', false);
        });
    },
    async contactUpdate(context,data){
        const vm = new Vue();
        context.commit('SET_LOADING_CONTACT', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${data.id}/contact/update`,data.contact)
        .then(() => {
            context.commit('SET_LOADING_CONTACT', false);
            vm.$toasted.success('Contact were saved successfully');  
        })
        .catch(() => {
            context.commit('SET_LOADING_CONTACT', false);
        });
    },
    async billingUpdate(context,data){
        const vm = new Vue();
        context.commit('SET_LOADING_BILLING', true);
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${data.id}/billing/update`,data.billing)
        .then(() => {
            context.commit('SET_LOADING_BILLING', false);
            vm.$toasted.success('Billing were saved successfully');     
        })
        .catch(() => {
            context.commit('SET_LOADING_BILLING', false);
        });
    },
    async filesUpdate(context,data){
        context.commit('SET_LOADING_FILES', true);
        const vm = new Vue();
        let files = new FormData();
        for (var i = 0; i < data.files.length; i++) {
            files.append(`files[${i}]`, data.files[i]);
        }
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${data.id}/upload-files`,files, { headers: { 'Content-Type': 'multipart/form-data'}})
        .then(() => {
            context.commit('SET_LOADING_FILES', false);
            vm.$toasted.success('Files were saved successfully');     
        })
        .catch(() => {
            context.commit('SET_LOADING_FILES', false);
        });
    },

    async getEditWorkOrder(context,id){
        context.commit('SET_LOADING_WORK_ORDER', true);
        await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/workorders/${id}/edit`)
        .then(result => {
            context.commit('SET_WORK_ORDER', result.data.data);
            context.commit('SET_LOADING_WORK_ORDER', false);
        })
        .catch(() => {
            context.commit('SET_LOADING_WORK_ORDER', false);
        });
    },
    async getEditPositions(context,id){
        await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/workorder/${id}/positions`)
        .then(result => {
            context.commit('SET_POSITIONS', result.data.data);
        })
        .catch(() => {
        });
    },
};