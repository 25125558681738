import Vue from 'vue';
import Paginate from 'vuejs-paginate';
import VuePhoneNumberInput from "vue-phone-number-input";
import Multiselect from 'vue-multiselect';
import {DatePicker, Select, Option} from 'element-ui';
import vClickOutside from 'v-click-outside';
import vSelect from 'vue-select';
import Toasted from 'vue-toasted';


import "vue-phone-number-input/dist/vue-phone-number-input.css";
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-select/dist/vue-select.css';


Vue.component('paginate', Paginate);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("vue-multiselect", Multiselect);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Option.name, Option);
Vue.component(Select.name, Select);
Vue.use(vClickOutside);
Vue.component('v-select', vSelect)
Vue.use(Toasted, { position: 'top-center', duration: 5000 });